/**
 * Showing and hiding icons on the map
 * depending on the zoom level
 *
 * @param {number} currZoom
 */

export default function ShowHidePlacesOnMap(currZoom) {
  const curentZoomeLevel = Math.floor(currZoom);

  window["resultsPlaces"].map(({ id, icon, children }) => {
    let layerName = icon?.replace(/icon-/g, "");

    const layerNameWithId = `${layerName}-${id}`;

    children.map(({ id, name, zoom_levels, area }) => {
      const zoomLevelsArray = zoom_levels;
      const zoomLevelsInArray = zoomLevelsArray.includes(curentZoomeLevel);

      // check if area exist in place
      if (area) {
        // console.log(curentZoomeLevel);

        if (zoomLevelsInArray) {
          window[`area-${id}`].addTo(window[layerNameWithId]);
        }
        if (!zoomLevelsInArray) {
          window[layerNameWithId].removeLayer(window[`area-${id}`]);
        }
      }

      if (window[`marker-${id}`] === undefined) return;

      if (zoomLevelsInArray) {
        const layerExistCheck = map.hasLayer(window[`marker-${id}`]);

        if (layerExistCheck) return;
        window[`marker-${id}`].addTo(window[layerNameWithId]);
      }

      if (!zoomLevelsInArray) {
        const layerExistCheck = map.hasLayer(window[`marker-${id}`]);

        // check if popup is open
        // const popup = L.popup();
        // console.log(popup.isOpen());

        if (!layerExistCheck) return;
        window[layerNameWithId].removeLayer(window[`marker-${id}`]);
      }
    });
  });
}
