import { select, selectAll } from "@utils/function";

// get data from places
// search name_tracking
// check if lat/lng exist in json if not dont show element

function SearchPlaceInInformation() {
  const topSectionInfo = select(".top-section-info");
  const informationSearchClass = select(".information-search");

  if (!informationSearchClass) {
    topSectionInfo.insertAdjacentHTML(
      "afterbegin",
      createSearchInpuInInformation()
    );
  }

  const searchInput = select("#search-information");
  const resultPlace = select(".result-place");
  const sidebar = select(".sidebar");
  const informationContent = select(".information-content");

  document.addEventListener("click", (e) => {
    const target = e.target;
    e.stopPropagation();

    // click on clear button
    if (target.closest(".clear-search-information")) {
      clearResultAndRemoveTextInInput(searchInput);
    }

    // click on result item
    if (target.closest(".result-item")) {
      clearResultAndRemoveTextInInput(searchInput);
    }

    // click on result item and search input
    if (target === searchInput || target === resultPlace) {
      const searchInputWidth = searchInput.offsetWidth;
      const sidebarHeight = sidebar.clientHeight;
      const informationContentHeight =
        informationContent.clientHeight - sidebarHeight - 102;

      resultPlace.style = `
        --info-height: ${informationContentHeight}px;
        --info-width: ${searchInputWidth}px;
      `;

      searchInput.addEventListener("click", (e) => {
        if (resultPlace.childNodes.length > 0) {
          showHideAriaExpanded("remove", "true");
        }
      });

      searchInput.addEventListener("input", (e) => {
        e.stopPropagation();
        e.preventDefault();
        const getInputWith = searchInput.offsetWidth;

        resultPlace.style.width = `${getInputWith}px`;

        let value = e.target.value;

        if (value && value.trim().length > 0) {
          value = value.trim().toLowerCase();

          showHideAriaExpanded("remove", "true");

          setList(
            HQ.Properties.places.filter((element) => {
              return element.name.match(new RegExp(value, "gi"));
            })
          );

          showHideButtonClear(true);
          // navigationThroughMenuItems();
        } else {
          showHideButtonClear(false);
          showHideAriaExpanded("add", "false");
          clearList();
        }
        return;
      });
    } else {
      showHideAriaExpanded("add", "false");
    }
  });
}

function navigationThroughMenuItems() {
  const activeMenu = "active-menu";
  let listItems = selectAll(".result-item");
  let curentLi = 0;

  listItems[curentLi]?.classList.add("active-menu");

  document.addEventListener("keydown", function (event) {
    const { code } = event;

    // console.log({
    //   code: event.code,
    //   key: event.key,
    //   keyCode: event.keyCode,
    //   which: event.which,
    // });

    // console.log(code);

    switch (code) {
      case "ArrowUp":
        listItems[curentLi].classList.remove(activeMenu);

        curentLi = curentLi > 0 ? --curentLi : 0;
        listItems[curentLi].classList.add(activeMenu);
        break;
      case "ArrowDown":
        listItems[curentLi].classList.remove(activeMenu);

        const listElementLength = listItems.length - 1;

        curentLi =
          curentLi < listElementLength ? ++curentLi : listElementLength;
        listItems[curentLi].classList.add(activeMenu);

        break;
    }

    if (curentLi == listItems.length - 1) {
      curentLi = 0;
      console.log("curentLi", curentLi);
    }
  });
}

/**
 * Add or remove class hidden and change aria-expanded attribute
 *
 * @param {string} type
 * @param {string} attribute
 */
function showHideAriaExpanded(type, attribute) {
  const resultPlace = select(".result-place");
  const searchInput = select("#search-information");

  resultPlace.classList[type]("hidden");
  searchInput.setAttribute("aria-expanded", attribute);
}

/**
 * Set list with results
 *
 * @param {array} results
 */
function setList(results) {
  clearList();

  for (const row of results) {
    createRow(row);
  }
}

/**
 * Show or hide button clear
 *
 * @param {boolean} show
 */
function showHideButtonClear(show) {
  const clearSearchInformationButton = select(".clear-search-information");
  clearSearchInformationButton.classList[show ? "remove" : "add"]("hidden");
}

/**
 * Clear result and remove text in input
 *
 * @param {HTMLElement} searchInput
 */
function clearResultAndRemoveTextInInput(searchInput) {
  searchInput.value = "";
  clearList();
  showHideButtonClear(false);
}

/**
 * Clear list
 */
function clearList() {
  const resultPlace = select(".result-place");
  resultPlace.innerHTML = "";
}

/**
 * Create row for result list
 *
 * @param {object} row
 */
function createRow(row) {
  const { latitude, longitude, parent_id, id, name } = row;

  if (!latitude || !longitude) return;

  const showIDIfSuperAdmin = HQ.Properties.userType ? ` (${id})` : "";
  const dataLatlng = `data-latlng="${latitude},${longitude}"`;
  const dataPlaceId = `data-place-id="${id}"`;
  const dataIdParent = `data-id-parent="${parent_id}"`;

  const className =
    'class="d-flex align-items-center open-place information-children"';

  const templateRow = `
    <div ${dataLatlng} ${dataPlaceId} ${dataIdParent} ${className}>${name}${showIDIfSuperAdmin}</div>
  `;

  const resultItem = document.createElement("li");
  resultItem.classList.add("result-item");
  resultItem.insertAdjacentHTML("beforeend", templateRow);
  list.appendChild(resultItem);
}

/**
 * Create search input in information
 *
 * @returns HTMLElement
 */
function createSearchInpuInInformation() {
  const searchInformation = `
    <div class="information-search">
        <div class="d-flex align-items-center">
        <input type="text" id="search-information" autocomplete="off" placeholder="Search place" class="form-control" aria-expanded="false">
        </div>
        <button class="clear-search-information hidden" aria-label="Clear search"></button>
        <ul class="result-place list-unstyled hidden" id="list"></ul>
    </div>
    `;

  return searchInformation;
}

export default SearchPlaceInInformation;
