// translate text on site
import map from "@components/map";
import CleareActiveSlider from "@components/sidebar/cleareActiveSlider";
import AnalyticsTracking from "@helpers/analyticsTracking";
import { convertJSONToArray, select } from "@utils/function";
import CustomIcon from "../customIcon";
import RemoveTeeOff from "../removeTeeOff";

import "../markerSlideTo";

// --------------------------------------------------
// flights layer
window["flights"] = L.featureGroup().addTo(map);

/**
 * Set flights on map
 *
 * @param {object} flights
 */
function MatchPlayFlightsSetOnMap(flights) {
  if (!HQ.Properties.showGroups) return;

  flights = convertJSONToArray(flights)
    .map((obj) => convertJSONToArray(obj))
    .flat();

  flights.map((flight, index) => {
    const {
      leader,
      leader_shortname,
      matchnumber,
      lat,
      lng,
      matchnumber_str,
      currenthole,
      status,
    } = flight;

    if (window[matchnumber_str] !== undefined) {
      // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/276
      // remove layer from map when status marker is FINISHED
      if (status === "FINISHED") {
        setTimeout(() => {
          map.removeLayer(window[matchnumber_str]);
        }, 2500);
        return;
      }

      // check if window[matchnumber_str] undefined
      if (window["flights"].hasLayer(window[matchnumber_str])) {
        // console1.log("yes", matchnumber_str);
        if (lat) {
          MatchPlayFlightsUpdate(flight);
          MatchPlayFlightsMarkerColorUpdate(flight);
        }
        // console.log(matchnumber_str);
        return;
      } else {
        // console.log("no");
      }
    }

    if (!lat) return;

    // -------------------------------------------
    // popup content

    const content = ShowMatchPlay(flight);
    const popup = L.popup().setContent(content);

    // const popup = L.popup().setContent("test");

    window[matchnumber_str] = L.marker([lat, lng], {
      layerID: matchnumber,
      // https://leafletjs.com/reference.html#marker-zindexoffset
      // zIndexOffset: 500,
      icon: CustomIcon({
        icon: "icon-flights",
        content: matchnumber,
        color: FlightMarkerColor(leader_shortname),
        matchnumber_str,
        players: GetAllPlayersID(flight),
        curentHole: currenthole,
      }),
    });

    window[matchnumber_str]
      .bindPopup(popup, {
        className: "group-popup",
        autoPanPadding: [20, 160],
      })
      .addTo(window["flights"])
      .on("click", (e) => {
        map.panTo(e.latlng);

        // let px = map.project(e.popup._latlng);
        // px.y -= e.popup._container.clientHeight / 2;
        // map.panTo(map.unproject(px));

        AnalyticsTracking("clickGroup", {
          name: "flights",
          icon: "icon-flights",
          icon_shortcode: "",
          description: "",
          lat: Number(lat),
          lng: Number(lng),
          group_partner: HQ.Properties.appGroupPartner,
        });

        CleareActiveSlider();

        // console.log(matchnumber_str);
      });

    // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/281
    // remove teeoff marker and popup when players start playing
    if (status === "PLAYING") {
      RemoveTeeOff();
    }
  });
}

/**
 * Move marker on new position
 *
 * @param {Object} flight
 */
function MatchPlayFlightsUpdate(flight) {
  const { lat, lng, matchnumber_str } = flight;

  if (lat === undefined) {
    // window[matchnumber_str].remove();
    window["flights"].removeLayer(window[matchnumber_str]);
    console.log("remove layer", matchnumber_str);

    return;
  }

  const content = ShowMatchPlay(flight);
  window[matchnumber_str].setPopupContent(content);

  // for test: 36.392010353688526, -5.2221107482910165

  if (typeof lat !== "undefined" || typeof lng !== "undefined") {
    window[matchnumber_str].slideTo([lat, lng], {
      duration: 2000,
    });
  }
}

/**
 * Create data in popup for matchplay
 *
 * @param {Object} flight
 */
function ShowMatchPlay(flight) {
  let {
    leader,
    isActive,
    roundNo,
    roundName,
    standing,
    holesplayed,
    leader_shortname,
    matchNo,
    currenthole,
    status,
    opponent_firstname,
    opponent_lastname,
    opponentpartner_firstname,
    opponentpartner_lastname,
    partner_firstname,
    partner_lastname,
    player_firstname,
    player_lastname,
  } = flight;

  const classIsActive = isActive ? "color-reverse" : "color";

  if (leader_shortname === "USA") {
    leader = ColorStanding("usa", classIsActive, standing);
  } else if (leader_shortname === "EUR") {
    leader = ColorStanding("eur", classIsActive, standing);
  } else {
    leader = ColorStanding("as", classIsActive, standing);
  }

  const opponent = opponent_firstname
    ? `<div class="mp-player">${opponent_firstname} ${opponent_lastname}</div>`
    : "";

  const opponentPartner = opponentpartner_firstname
    ? `<div class="mp-player">${opponentpartner_firstname} ${opponentpartner_lastname}</div>`
    : "";

  const partner = partner_firstname
    ? `<div class="mp-player">${partner_firstname} ${partner_lastname}</div>`
    : "";

  const player = player_firstname
    ? `<div class="mp-player">${player_firstname} ${player_lastname}</div>`
    : "";

  const thru = status === "FIN" ? `FIN ${holesplayed}` : `thru ${holesplayed}`;

  const sponsorGroupPartner = HQ.Properties.appSponsorGroupPartner;

  const logo = sponsorGroupPartner
    ? `<img class="img-fluid group-partner-logo" src="${sponsorGroupPartner}">`
    : "";

  const template = `
    <div class="mp-wrapper">
      <div class="mp-head mb-2">
          <div class="row">
            <div class="col">
              <div class="mp-round-name">${roundName}</div>
              <div class="mp-hole">${HQ.Properties.i18n.popupContentMatchPlay.match}: ${matchNo} <br> ${HQ.Properties.i18n.popupContentMatchPlay.hole}: ${currenthole}</div>
            </div>
            <div class="col d-flex align-self-center">
              ${logo}
            </div>
          </div>
      </div>
      <h5>Players</h5>
      <div class="mp-body">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">

            <div class="row">
              <div class="col-3 col-flag center-xy">
                <span title="EUR" class="flag-icon flag-icon-match flag-icon-eur"></span>
              </div>
              <div class="col-9 col-players center-xy flex-column">
                ${player}
                ${partner}
              </div>
            </div>

            <div class="row">
              <div class="col-3 col-flag center-xy">
                <span title="USA" class="flag-icon flag-icon-match flag-icon-usa"></span>
              </div>
              <div class="col-9 col-players center-xy flex-column">
                ${opponent}
                ${opponentPartner}
              </div>
            </div>

          </div>
          <div class="col-4 d-flex flex-column justify-content-center align-self-center">
            <div class="team-leader-up">${leader}</div>
            <div class="team-thru text-center">${thru}</div>
          </div>
        </div>

      </div>
    </div>
  `;

  return template;
}

/**
 * Color depends on leader
 *
 * @param {Number} leader
 * @returns String
 */
function FlightMarkerColor(leader_shortname) {
  let flightMarkerColor;
  if (leader_shortname === "USA") {
    flightMarkerColor = "#c81414";
  } else if (leader_shortname === "EUR") {
    flightMarkerColor = "#003c82";
  } else {
    flightMarkerColor = "#898989";
  }
  return flightMarkerColor;
}

/**
 * Create array with players id
 *
 * @param {Object} flight
 * @returns Array
 */
function GetAllPlayersID(flight) {
  const {
    opponent_idglobal,
    opponentpartner_idglobal,
    partner_idglobal,
    player_idglobal,
  } = flight;

  let holeCurent = [];

  if (opponent_idglobal) {
    holeCurent.push(opponent_idglobal);
  }
  if (opponentpartner_idglobal) {
    holeCurent.push(opponentpartner_idglobal);
  }
  if (partner_idglobal) {
    holeCurent.push(partner_idglobal);
  }
  if (player_idglobal) {
    holeCurent.push(player_idglobal);
  }

  return holeCurent;
}

/**
 * Update color icon dependence on leader
 *
 * @param {Object} flight
 */
function MatchPlayFlightsMarkerColorUpdate(flight) {
  let { matchnumber, leader_shortname } = flight;

  const flightIcon = select(`[data-id-flightnumber-map="${matchnumber}"]`);
  flightIcon?.style.setProperty(
    "background-color",
    FlightMarkerColor(leader_shortname)
  );
}

/**
 * Standing (4 UP|1 UP in popup) color dependence on leader number
 *
 * @param {String} country
 * @param {String} classIsActive
 * @param {String} standing
 */
function ColorStanding(country, classIsActive, standing) {
  return `<div class="color-standing ${classIsActive}-${country}">${standing}</div>`;
}

export { MatchPlayFlightsSetOnMap };
