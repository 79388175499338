import {
  capitalize,
  clearei18n,
  select,
  selectAll,
  showDistanceToPlace,
} from "@utils/function";
import { defaultInformationSettings } from "@helpers/defaults";
import { iconsType } from "./iconsType";
import { InformationSettings } from "@components/sidebar/informationSettings";
import SearchPlaceInInformation from "./searchInformation";

// show gorup icons witch "switch" inside information
// HQ.Properties.showSwitchGorups

/**
 * Retirn a row for auto complete
 * @param {object} objectArrays
 * @returns
 */
function ShowInformationAboutPlacesRow(objectArrays, config) {
  const { allLayers, allGroups } = defaultInformationSettings;

  HQ.Properties.infoSecondLevel = HQ.Properties.showSwitchGorups
    ? [allLayers, allGroups, ...objectArrays]
    : [allLayers, ...objectArrays];

  const { type, sort, id: configId, layer: configName } = config || {};

  // sort name (a-z)
  //   if (type === "update" && sort == 3) {
  // newArrayObject.sort((a, b) =>
  //   a.id === 99 ? -1 : b.id === 99 ? 1 : a.name.localeCompare(b.name)
  // );
  // newArrayObject = newArrayObject
  //   .filter((e) => e.id === 99 || e.id === 0)
  //   .concat(
  //     newArrayObject
  //       .filter((e) => e.id !== 99 && e.id !== 0)
  //       .sort((a, b) => a.name.localeCompare(b.name))
  //   );
  //   }

  return HQ.Properties.infoSecondLevel
    .map(({ id, icon, icon_shortcode, name, children }, index) => {
      let layerName = icon?.replace(/icon-/g, "");

      let { color, color_front, color_outline } = children[0] || {};

      color = color || "";
      color_front = color_front || "";
      color_outline = color_outline || "";

      // children id
      const childrenID = children.map((child) => child.id).sort();

      const childrenObj = children[0];
      let colorBackground = childrenObj?.color;
      colorBackground = colorBackground ? colorBackground : null;

      // -----------------------------
      // children

      const childrenLength = children.length;

      // add sub level
      if (
        type === "create" &&
        childrenLength > 1 &&
        id === configId &&
        HQ.Properties.informationVersion == "v2"
      ) {
        createAllChildrenRows(layerName, children, id);
        return;
      }

      const showArrowToShowChildren =
        childrenLength > 1 && HQ.Properties.informationVersion == "v2"
          ? `
              <div class="information-row-arrow">
                <svg fill="#000">
                  <use href="#icon-plus" class="icon-plus"></use>
                  <use href="#icon-minus" class="icon-minus"></use>
                </svg>
              </div>`
          : "";

      // -----------------------------

      let template = "";

      const switcher = `
        <div class="switch-container switch-information">
          <label class="switch" for="checkbox">
            <input type="checkbox" name="checkbox-switcher" class="checkbox-switcher" id="checkbox-${id}" />
            <div class="swich-slider round"></div>
          </label>
        </div>
      `;

      // create row text
      let removeNumberFromName =
        icon === "icon-hole" ? name.replace(/[0-9]/g, "") : name;

      // set the color of the "Group" icon in "information",
      // if there is no--color - group variable, set the color to red
      const colorForFlights =
        icon === "icon-flights" ? "var(--color-group, #ff0000)" : color;

      // set type of icon
      const iconType =
        icon === "icon-restpoint"
          ? InfoRowIcon(
              icon,
              icon_shortcode,
              colorForFlights,
              color_front,
              color_outline
            )
          : InfoRowIcon(icon, layerName, colorForFlights, color_front);

      const trackingData = `${icon}, ${removeNumberFromName
        .toLowerCase()
        .trim()}`;

      const { latitude, longitude } = getLatLongFromPlace(id);
      const placeLatLng =
        latitude && childrenLength <= 1
          ? `data-latlng="${latitude},${longitude}"`
          : "";

      const goToPlace =
        latitude &&
        childrenLength <= 1 &&
        layerName !== "flights" &&
        layerName !== "show-all-layers" &&
        HQ.Properties.informationVersion == "v2"
          ? "open-place information-parent "
          : "";

      const cursorPointer =
        (HQ.Properties.informationVersion == "v2" && childrenLength > 1) ||
        (latitude &&
          childrenLength <= 1 &&
          layerName !== "flights" &&
          layerName !== "show-all-layers")
          ? "cursor-pointer "
          : "";

      // set layer id
      //   const typeOfMap = HQ.Properties.showSwitchGorups ? 1 : 0;
      //   let layerId = index > typeOfMap ? `${layerName}-${id}` : layerName;

      let layerId =
        layerName !== "flights" && layerName !== "show-all-layers"
          ? `${layerName}-${id}`
          : layerName;

      const defaultRow = `
        <div ${placeLatLng} data-id=${id} data-layer-id="${layerId}"  data-place-id="${id}" data-tracking="${trackingData}" class="d-flex align-items-center information-row ${cursorPointer}${goToPlace}" data-children="${childrenID}">
          ${iconType}
          ${InfoRowName(capitalize(removeNumberFromName), id)}
          ${showArrowToShowChildren}
          ${switcher}
        </div>
      `;

      // const sortSelect = sortInformationRowByOption();

      const rowInfo =
        index === 0
          ? `
            <div class="top-section-info">
              <div class="d-flex align-items-center">
                <div class="top-section-info-sort"></div>
                <div class="ms-auto top-section-info-clear">
                  ${defaultRow}
                </div>
              </div>
            </div>`
          : defaultRow;

      // ${childrenRows}
      template += rowInfo;

      return template;
    })
    .join("");
}

/**
 * Get latitude and longitude from place
 *
 * @param {number} placeID
 */
function getLatLongFromPlace(placeID) {
  const findPlaceByID = HQ.Properties.places
    .filter((c) => c.id == placeID)
    .map((el) => el)
    .pop();

  const { latitude, longitude } = findPlaceByID || {};

  return { latitude, longitude };
}

/**
 * Create all childrens rows
 *
 * @param {array} childrens
 * @param {number} idParent
 * @returns {string}
 **/
function createAllChildrenRows(layerName, childrens, idParent) {
  const youLatLng = document.documentElement.style.getPropertyValue("--lat");

  // Remove first child element this element this element should be be a parent element
  childrens = layerName === "hole" ? childrens : childrens.slice(1);

  const informationContentHead = select(".information-content");
  const sortType = informationContentHead.dataset.sort;

  // remove TEE OFF from hole sub menu
  if (layerName === "hole") {
    childrens = childrens.filter((x) => x.id !== 19 && x.id !== 20);
  }

  let newArray = childrens.map((el) => ({
    ...el,
    distance: el.latitude
      ? showDistanceToPlace(`${el.latitude},${el.longitude}`)
      : 0,
  }));

  // sort children depence on sortType
  if (sortType == 1) {
    newArray.sort((a, b) => a.distance - b.distance);
  } else if (sortType == 3) {
    newArray.sort((a, b) => a.name.localeCompare(b.name));
  }

  const childrenRows = newArray
    .map((children) => {
      const {
        id,
        icon,
        name,
        icon_shortcode,
        color_front,
        color_outline,
        color,
      } = children;

      let layerName = icon?.replace(/icon-/g, "");

      const colorForFlights =
        icon === "icon-flights" ? "var(--color-group, #ff0000)" : color;

      const iconType =
        icon === "icon-restpoint"
          ? InfoRowIcon(
              icon,
              icon_shortcode,
              colorForFlights,
              color_front,
              color_outline
            )
          : InfoRowIcon(icon, layerName, colorForFlights, color_front);

      const { latitude, longitude } = getLatLongFromPlace(id);
      const placeLatLng = `${latitude},${longitude}`;

      // do not show row if level 2 not have latlng
      if (!latitude || !longitude) return;

      const distanceToPlace = showDistanceToPlace(placeLatLng, "meters");

      const dist = distanceToPlace <= 10 ? "<10" : distanceToPlace;

      const showDistance =
        youLatLng && latitude
          ? `<div class="ms-auto me-2 distance-to-place">${dist}m</div>`
          : "";

      const showIDIfSuperAdmin = HQ.Properties.userType ? ` (${id})` : "";

      return `
      <div data-latlng="${placeLatLng}" data-place-id="${id}" data-id-parent="${idParent}" class="d-flex align-items-center open-place information-children">
        ${iconType}
        ${capitalize(name)}${showIDIfSuperAdmin}
        ${showDistance}
      </div>
    `;
    })
    .join("");

  const template = `<div class="info-level-2">${childrenRows}</div>`;

  const parentElement = select(`[data-place-id="${idParent}"]`);
  parentElement.insertAdjacentHTML("afterend", template);
}

/**
 * Set icon - information sidebar
 *
 * @param {string} iconName
 * @param {string} layerName
 * @param {string} color
 * @returns
 */
function InfoRowIcon(iconName, layerName, color, color_front) {
  return `<div class="icon ${iconName} information-row-icon">${iconsType({
    icon: iconName,
    content: layerName,
    place: "information",
    color,
    color_front,
  })}</div>`;
}

/**
 * Set information row - information sidebar
 *
 * @param {string} layerName
 * @returns
 */
function InfoRowName(layerName, id) {
  const showIDIfSuperAdmin = HQ.Properties.userType ? ` (${id})` : "";

  const i18nData = clearei18n(layerName);

  const i18JSON = HQ.Properties.i18n["information"][i18nData];

  // console.log("i18JSON", i18JSON);

  const rowText = i18JSON ? i18JSON : layerName;

  return `<div class="information-row-name me-auto">${rowText}${showIDIfSuperAdmin}</div>`;
}

/**
 * Sort information row by option
 */
function SortInformationRowByOption() {
  const topSectionInfoSort = select(".top-section-info-sort");
  topSectionInfoSort.textContent = "";
  topSectionInfoSort.insertAdjacentHTML("afterbegin", selectTemplate());

  // --------------------------------------------------
  // sort inside information tab

  const sortSelect = select(".list-sort");

  sortSelect?.addEventListener("change", (e) => {
    const informationContentHead = select(".information-content");
    const target = e.target;

    informationContentHead.dataset.sort = target.value;

    const config = {
      type: "update",
      sort: target.value,
    };

    const informationContent = select(".information-content .slider-content");
    informationContent.textContent = "";
    informationContent?.insertAdjacentHTML(
      "beforeend",
      ShowInformationAboutPlacesRow(window["resultsPlaces"], config)
    );

    SortInformationRowByOption();

    const getAllDataLayerId = selectAll("[data-layer-id]");
    const getAllCheckboxes = selectAll(".switch > input");
    InformationSettings(getAllDataLayerId, getAllCheckboxes);

    // add search input in information
    SearchPlaceInInformation();
  });
}

function selectTemplate() {
  const lat = document.documentElement.style.getPropertyValue("--lat");

  const informationContentHead = select(".information-content");
  let sortType = informationContentHead.dataset.sort;

  // set sort when first call
  if (!sortType) {
    informationContentHead.dataset.sort = lat ? 1 : 2;
  }

  let sortArray = [
    { id: 1, name: "Distance" },
    { id: 2, name: "Default" },
    { id: 3, name: "Name (A-Z)" },
  ];

  sortArray = lat ? sortArray : sortArray.slice(1);

  const option = sortArray
    .map(({ id, name }) => {
      const selected = id == sortType ? "selected" : "";

      return `<option value="${id}" ${selected}>${name}</option>`;
    })
    .join("");

  return `
    <div class="sort-select d-flex">
      <label for="list-sort">Sort:</label>
      <select tabindex="distance" name="list-sort" id="list-sort" class="form-select ps-2 list-sort">
        ${option}
      </select>
    </div>
  `;
}

export { ShowInformationAboutPlacesRow, SortInformationRowByOption };
