import AnalyticsTracking from "@helpers/analyticsTracking";
import { quickBarElements } from "@helpers/defaults";
import { select } from "@utils/function";

/**
 * Show player for bigsacreen
 * open popup
 */
export default function ShowPlayerPopupBigscreen(
  timePerItem,
  items,
  centerZoom,
  centerLatlng
) {
  let itemsArray = items.split(",");
  let currentIndex = 0;

  // set center
  if (centerLatlng) {
    const [lat, lng] = centerLatlng.split(",").map(Number);
    map.setView([lat, lng], centerZoom);
  }

  function arrayIdx() {
    if (currentIndex >= itemsArray.length) {
      currentIndex = 0;
    }
    return currentIndex++;
  }

  // first show popup
  openPopup(itemsArray[arrayIdx()]);

  // show popup every timePerPlayer seconds * 1000
  setInterval(() => {
    openPopup(itemsArray[arrayIdx()], centerZoom);
  }, timePerItem * 1000);
}

/**
 * Open popup (group flights)
 *
 * @param {string} string
 */
function openPopup(string, centerZoom) {
  //   console.log("string", string);
  const typeOfPlace = checkStringType(string);

  if (typeOfPlace === "qucikbar") {
    const correctName = findQuickBarCorrectName(quickBarElements, string);
    const quickbaritem = select(`.${correctName}`);
    // console.log(quickbaritem);
    quickbaritem?.click();
  } else {
    const activeSlider = select(".active-slider");
    activeSlider?.classList.remove("active-slider");

    let checkPlayer = false;

    const placeId = typeOfPlace === "place" ? string.replace("p", "") : string;

    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        const checkPlayerItem = L.DomUtil.hasClass(
          layer.options.icon.options,
          `${typeOfPlace}-${placeId}`
        );

        // player
        if (checkPlayerItem && !checkPlayer) {
          const { lat, lng } = layer._latlng;

          layer.openPopup();
          map.setView([lat, lng], centerZoom);

          const nameEvent =
            typeOfPlace === "place" ? "clickPlace" : "clickGroup";

          const eventName = typeOfPlace === "place" ? "place" : "flight";

          let getIconName = "icon-flight";
          if (typeOfPlace === "place") {
            getIconName = layer.options.icon.options.className.split(" ")[1];
          }

          const iconName =
            typeOfPlace === "place" ? getIconName : "icon-flights";

          if (typeOfPlace === "player") {
            layer._icon.classList.add("animation");
          }

          AnalyticsTracking(nameEvent, {
            name: eventName,
            icon: iconName,
            icon_shortcode: "",
            description: "",
            lat: Number(lat),
            lng: Number(lng),
            group_partner: HQ.Properties.appGroupPartner,
          });

          checkPlayer = true;
        }
      }
    });
  }
}

/**
 * Check string type
 *
 * @param {string} str
 * @returns String
 */
function checkStringType(str) {
  if (/^\d+$/.test(str)) {
    // check if player (only number)
    return "player";
  } else if (str.startsWith("p")) {
    // check if place (strig start with p)
    return "place";
  } else {
    // string is not a number and not a place
    return "qucikbar";
  }
}

/**
 * Find quickbar correct name
 *
 * @param {array} array
 * @param {string} value
 */
function findQuickBarCorrectName(array, value) {
  for (const i in array) {
    if (array[i].name === value) {
      return array[i].qvalue;
    }
  }
}
