// --------------------------------------------------
// initial map

import map from "@components/map";

import "@components/sidebar";
import "@components/map/iconsLayers";

import {
  GetCenterOfMap,
  BackToHomeButton,
} from "@components/map/buttonBackToHome";

import LocationButton from "@components/map/buttonLocation";

import {
  InformationSettings,
  InformationSettingsUpdate,
} from "@components/sidebar/informationSettings";

import AutocompleteObj from "@components/sidebar/autocomplete";

import {
  FlightsSetOnMap,
  FlightsAddAnimationToMarker,
} from "@components/map/flights";

import { PopupCreate, PopupDestroy } from "@helpers/popup";

import {
  LeaderboardCreateRow,
  LeaderBoardAddFavoritePlayer,
  LeaderboardContentSlider,
} from "@components/sidebar/leaderboard";

import HighlightsCreateRow from "@components/sidebar/highlightsCreateRow";
import ProgrammeCreateRow from "@components/sidebar/programmeCreateRow";

import {
  body,
  checkApID,
  checkIfHeaderExist,
  convertJSONToArray,
  getBounds,
  getItemLocalStorage,
  hasTouchScreen,
  markFavoritePlayers,
  objectLength,
  select,
  selectAll,
  setItemLocalStorage,
  setPropertyHeight,
  updatePopupHeightCalculate,
  eraseCookie,
  removeIdFromLocalStorage,
  ClipboardWriteText,
  CreateAdsSlotInLeaderBoard,
} from "@utils/function";

import ShowPlacePopup from "@components/map/showPlacePopup";
import ShowPlayerPopup from "@components/map/showPlayerPopup";
import ShowHidePlacesOnMap from "@components/map/showHidePlacesOnMap";
import ShowFirstLayerFlights from "@components/map/showFirstLayerFlights";
import LocateControl from "@components/map/locateControl";
import AddTwitsToMap from "@components/map/addTwitsToMap";
import UpdateHighlightsTwits from "@components/map/updateHighlightsTwits";
import {
  ShowInformationAboutPlacesRow,
  SortInformationRowByOption,
} from "@components/map/showInformationAboutPlacesRow";

import AddControlPlaceholders from "@components/map/additionalItems";
import UpdatePlaces from "@components/map/updatePlaces";

import CleareActiveSlider from "@components/sidebar/cleareActiveSlider";
import ActiveInformation from "@components/sidebar/activeInformation";
import ActiveGroupIsideInformation from "@components/sidebar/activeGroupIsideInformation";
import ScheduleTennis from "@components/sidebar/scheduleTennis";
import ResultsTennis from "@components/sidebar/resultsTennis";

import AnalyticsTracking from "@helpers/analyticsTracking";
import ShowMultCourseLayers from "@components/map/showMultiCourseLayers";

// mach play
import MachplaySearch from "@components/sidebar/matchPlay/playSearch";
import SwitchTeams from "@components/sidebar/matchPlay/switchTeamPlayerSearch";
import ShowSquadPoints from "@components/map/matchPlay/showSquadPoints";
import MachplayLeaderBoard from "@components/sidebar/matchPlay/playLeaderBoard";
import { MatchPlayFlightsSetOnMap } from "@components/map/matchPlay/flights";
import CreatePlayerOnRange from "@components/map/matchPlay/createPlayerOnRange";
import { timelineWrapper } from "@helpers/defaults";
import RyderCupLogos from "@components/navbar/rydercup";
import ShowPlayerPopupBigscreen from "@components/map/showPlayerPopupBigscreen";
import SearchPlaceInInformation from "@components/map/searchInformation";

// --------------------------------------------------

const root = document.documentElement;

// --------------------------------------------------
// create div from popup

PopupCreate();

// --------------------------------------------------
// add button botton, event open site and (i)

AddControlPlaceholders(map);

// --------------------------------------------------
// add app id to local storage

checkApID();

// ------------------------------------------------------------
// check if device has touch screen
// add to body class

root.style.setProperty(
  "--has-touch-screen",
  hasTouchScreen() ? "flex" : "none"
);

root.style.setProperty("--site-type", `site-${HQ.Properties.appType}`);

root.style.setProperty(
  "--touch-screen-players",
  hasTouchScreen() ? "0px" : "17px"
);

root.style.setProperty(
  "--header-height",
  checkIfHeaderExist() ? checkIfHeaderExist() : "0px"
);

// --------------------------------------------------
// action from livewire

window.addEventListener("updateLocator", (event) => {
  // event.preventDefault();
  // event.stopPropagation();

  HQ.Properties.top5 = event.detail.updateTopFive;
  HQ.Properties.currentRound = event.detail.updateCurrentRound;
  HQ.Properties.highlights = event.detail.updateHighlights;
  HQ.Properties.places = event.detail.updatePlaces;
  HQ.Properties.updateDataEntity = event.detail.updateDataEntity;

  // matchPlay
  HQ.Properties.squads = event.detail.updateSquads;
  HQ.Properties.players = event.detail.updatePlayers;
  HQ.Properties.matches = event.detail.updateMatches;

  // --------------------------------------------------------

  if (HQ.Properties.updateDataEntity == "entries") {
    HQ.Properties.layerFlights = convertJSONToArray(event.detail.updateEntries);
  } else {
    HQ.Properties.layerFlights = convertJSONToArray(event.detail.updateFlights);
  }

  window["updateEntries"] = convertJSONToArray(event.detail.updateEntries);

  // -----------------------------------------------------------------------

  // squads score update
  if (HQ.Properties.squads && objectLength(HQ.Properties.squads) > 0) {
    ShowSquadPoints(true);
  }

  // player search update matchPlay
  const matchplayPlayersIsActive = select(".matchplay-players-is-active");
  if (
    matchplayPlayersIsActive &&
    HQ.Properties.players &&
    objectLength(HQ.Properties.players) > 0
  ) {
    console.log("update players", HQ.Properties.players);

    MachplaySearch(true);
  }

  // leaderboard update matchPlay
  const matchplayLeaderboardIsActive = select(
    ".matchplay-leaderboard-is-active"
  );
  if (HQ.Properties.matches && objectLength(HQ.Properties.matches) > 0) {
    // update popup content matchPlay
    MatchPlayFlightsSetOnMap(HQ.Properties.matches);

    if (matchplayLeaderboardIsActive) {
      MachplayLeaderBoard(true);
    }
  }

  // -----------------------------------------------------------------------

  const leaderboardIsActive = select(".leaderboard-is-active");

  // update leaderboard
  if (
    HQ.Properties.top5 &&
    objectLength(HQ.Properties.top5) > 0 &&
    leaderboardIsActive
  ) {
    // update rund number in leaderboard
    const roundNumber = select(".round-number");
    roundNumber.textContent =
      +HQ.Properties.currentRound > 0 ? HQ.Properties.currentRound : "";

    // add new players to leaderboard
    LeaderboardContentSlider.insertAdjacentElement(
      "beforeend",
      LeaderboardCreateRow("update")
    );

    LeaderBoardAddFavoritePlayer();

    if (HQ.Properties.showAds == "1") {
      CreateAdsSlotInLeaderBoard("update");
    }
  }

  // -------------------------------------------------------
  // add/remove highlights twits
  // from highlights slider

  const highlightsIsActive = select(".highlights-is-active");
  if (highlightsIsActive) {
    HighlightsCreateRow();
  }

  // add/remove highlights twits
  // console.log("highlights", HQ.Properties.highlights);
  UpdateHighlightsTwits();

  // hilight flightsmark the favored players
  markFavoritePlayers();

  // check if places array > 0
  // then run update places
  if (HQ.Properties.places?.length > 0) {
    UpdatePlaces();
  }

  if (HQ.Properties.layerFlights.length > 0) {
    // check if layer flights is empty window['flights]
    // if (isObjectEmpty(flightsLayer._layers)) {
    // set new flights(group) on map
    FlightsSetOnMap(HQ.Properties.layerFlights);

    // removeLocalStorage("informationSettings");
    FlightsAddAnimationToMarker(window["flights"]);
  }
});

// --------------------------------------------------
// set match play on site start

MatchPlayFlightsSetOnMap(HQ.Properties.matches);

// L.marker([53.325876, 10.235685]).addTo(map).bindPopup("Center Warsaw");

// --------------------------------------------------
// set first flight on site start

FlightsSetOnMap(HQ.Properties.layerFlights);

// console.log("first", HQ.Properties.layerFlights);

// flighs layer to map
window["flights"].addTo(map);

FlightsAddAnimationToMarker(window["flights"]);

// --------------------------------------------------
// add all icons and information to information-conten

if (HQ.Properties.typeSite === "locator") {
  const informationContent = select(".information-content .slider-content");
  informationContent?.insertAdjacentHTML(
    "beforeend",
    ShowInformationAboutPlacesRow(window["resultsPlaces"])
  );
}

// --------------------------------------------------
// sidebar action

const sidebar = select(".sidebar");
const sliderWrapperContent = select(".slider-wrapper-content");

sidebar.addEventListener("click", (e) => {
  const { target } = e;

  e.preventDefault();
  e.stopPropagation();
  // get current zoom

  // set height element in slider without quickbar height and head hegiht
  setPropertyHeight(
    root,
    "--sidebar-height",
    `${sidebar.offsetHeight + sliderWrapperContent.offsetHeight}px`
  );

  const menuItem = target.closest(".menu-item");

  // const sidebarActive = select(".sidebar-active");
  // sidebarActive?.classList.remove("sidebar-active");

  // if (menuItem) {
  //   menuItem.classList.toogle("sidebar-active");
  // }

  // close all popup when click
  // on sidebar button
  map.closePopup();

  const informationIsActive = select(".information-is-active");
  const leaderboardIsActive = select(".leaderboard-is-active");
  const playersIsActive = select(".players-is-active");
  const highlightsIsActive = select(".highlights-is-active");
  const programmeIsActive = select(".programme-is-active");
  // const locationIsActive = select(".location-is-active");

  // config for analitics
  const quickBarPartnerNameAnalitics = {
    quickbar_partner: HQ.Properties.appSponsorQuickbarPartner,
    quickbar_partner_other: HQ.Properties.appSponsorQuickbarPartnerOther,
    tournament_partner_quickbar: HQ.Properties.appSponsorTournamentPartner,
  };

  // --------------------------------------------------
  // leaderboard
  if (menuItem.classList.contains("sidebar-leaderboard")) {
    const leaderboardContent = select(".leaderboard-content");

    if (leaderboardContent.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    if (objectLength(HQ.Properties.top5) > 0) {
      LeaderboardContentSlider.insertAdjacentElement(
        "beforeend",
        LeaderboardCreateRow("update")
      );

      LeaderBoardAddFavoritePlayer();

      if (HQ.Properties.showAds == "1") {
        CreateAdsSlotInLeaderBoard("open leaderboard");
      }
    } else {
      const text = `<div class="p-3">${HQ.Properties.i18n.leaderboard["noDataAvailable"]}</div>`;
      LeaderboardContentSlider?.insertAdjacentHTML("beforeend", text);
    }

    // acitve flight in information
    ActiveGroupIsideInformation(window["flights"]);

    if (!map.hasLayer(window["flights"])) {
      setTimeout(() => {
        body.classList.remove("flags-is-removed");
        // add flights to map
        window["flights"].addTo(map);
      }, 1000);
    }

    if (!leaderboardIsActive) {
      CleareActiveSlider();
    }

    setTimeout(() => {
      leaderboardContent.classList.add("active-slider");
      body.classList.add("leaderboard-is-active");
      menuItem.classList.add("active-slider-menu");
    }, 200);

    // tracking
    AnalyticsTracking("openLeaderboard", quickBarPartnerNameAnalitics);
  }

  // --------------------------------------------------
  // slider-info
  if (menuItem.classList.contains("sidebar-info")) {
    const informationContent = select(".information-content");

    if (informationContent.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    if (!informationIsActive) {
      CleareActiveSlider();
    }

    // set sorting select to information heafder
    if (HQ.Properties.informationVersion == "v2") {
      SortInformationRowByOption();

      // search place in information (quickbat);
      // show only when HQ.Properties.informationVersion == "v2"
      SearchPlaceInInformation();
    }

    setTimeout(() => {
      informationContent.classList.add("active-slider");
      menuItem.classList.add("active-slider-menu");
      body.classList.add("information-is-active");
    }, 200);

    // tracking
    AnalyticsTracking("openInformation", quickBarPartnerNameAnalitics);
  }

  // --------------------------------------------------
  // sidebar-search
  if (menuItem.classList.contains("sidebar-search")) {
    // --------------------------------------------------
    // players
    const playersContentSlider = select(".players-content .slider-content");

    window.data = [];

    try {
      window.data = convertJSONToArray(
        objectLength(HQ.Properties.layerFlights) > 0
          ? HQ.Properties.layerFlights
          : window["updateEntries"]
      );
    } catch (error) {}

    if (window.data.length > 0) {
      const noDataAvailable = select(".noDataAvailable");
      noDataAvailable?.remove();
      // we always remove div with results
      // and button when opening 'Player Search'
      const autoResultsWrapper = select(".search-autocomplete");
      const autoClear = select(".auto-clear");

      autoResultsWrapper?.remove();
      autoClear?.remove();

      // -----------------------------------------------------

      const autocomplete = `
        <div class="search-autocomplete">
          <div class="auto-search-wrapper max-height">
            <input type="text" id="search-players" autocomplete="off" placeholder="${HQ.Properties.i18n["autocomplete"].placeholder}" />
          </div>
        </div>`;

      playersContentSlider.insertAdjacentHTML("beforeend", autocomplete);

      window.autocomplete = new Autocomplete("search-players", AutocompleteObj);

      if (HQ.Properties.showAds == "1") {
        CreateAdsSlotInLeaderBoard("autocomplete");
      }
    } else {
      playersContentSlider.textContent = "";

      const text = `<div class="p-3 small-font noDataAvailable">${HQ.Properties.i18n.autocomplete["noDataAvailable"]}</div>`;
      playersContentSlider.insertAdjacentHTML("beforeend", text);
    }

    const playersContent = select(".players-content");

    // if click on Player Search change
    // checkbox inside Information - Group

    ActiveGroupIsideInformation(window["flights"]);

    if (!map.hasLayer(window["flights"])) {
      setTimeout(() => {
        body.classList.remove("flags-is-removed");
        window["flights"].addTo(map);
      }, 1000);
    }

    if (playersContent.classList.contains("active-slider")) {
      CleareActiveSlider();
      try {
        auto.destroy();
      } catch (error) {}
      return;
    }

    if (!playersIsActive) {
      CleareActiveSlider();
    }

    setTimeout(() => {
      playersContent.classList.add("active-slider");
      body.classList.add("players-is-active");

      menuItem.classList.add("active-slider-menu");

      const sliderContent = select(".active-slider > .slider-content");

      setPropertyHeight(
        root,
        "--slider-height",
        `${sliderContent.clientHeight - 110}px`
      );

      const searchPlayers = select("#search-players");
      const autoResultsWrapper = select(".auto-results-wrapper");

      // autocomplete add class and click on input
      autoResultsWrapper?.classList.add("auto-is-active");
      searchPlayers?.click();
    }, 200);

    // tracking
    AnalyticsTracking("openPlayersearch", quickBarPartnerNameAnalitics);
  }

  // --------------------------------------------------
  // highlights
  if (menuItem.classList.contains("sidebar-media")) {
    const highlightsContent = select(".highlights-content");

    if (highlightsContent.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    if (!highlightsIsActive) {
      CleareActiveSlider();
    }

    // --------------------------------------------------
    // set highlights information (twits)
    HighlightsCreateRow();

    setTimeout(() => {
      highlightsContent.classList.add("active-slider");
      body.classList.add("highlights-is-active");
      menuItem.classList.add("active-slider-menu");
    }, 200);

    // tracking
    AnalyticsTracking("openHighlights", quickBarPartnerNameAnalitics);
  }

  // --------------------------------------------------
  // slider-programme
  if (menuItem.classList.contains("sidebar-programme")) {
    const programmeContent = select(".programme-content");

    if (programmeContent.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    if (!programmeIsActive) {
      CleareActiveSlider();
    }

    setTimeout(() => {
      programmeContent.classList.add("active-slider");
      body.classList.add("programme-is-active");
      menuItem.classList.add("active-slider-menu");
    }, 200);

    // tracking
    AnalyticsTracking("openProgram", quickBarPartnerNameAnalitics);
  }

  // --------------------------------------------------
  // open new window if mode=iframe
  if (menuItem.classList.contains("sidebar-fullscreen-in")) {
    AnalyticsTracking("openFullscreen");
    window.open(HQ.Properties.currentUrl);
  }

  // TENNIS

  // --------------------------------------------------
  // results

  const resultsIsActive = select(".results-is-active");
  const resultsContentSlider = select(".results-content .slider-content");

  if (menuItem.classList.contains("sidebar-tennis-results")) {
    const resultsContent = select(".results-content");

    if (resultsContent.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    if (!resultsIsActive) {
      CleareActiveSlider();
    }

    resultsContentSlider.insertAdjacentElement("beforeend", ResultsTennis());

    setTimeout(() => {
      resultsContent.classList.add("active-slider");
      body.classList.add("results-is-active");
      menuItem.classList.add("active-slider-menu");
    }, 200);

    // tracking
    AnalyticsTracking("openResults", quickBarPartnerNameAnalitics);
  }

  // --------------------------------------------------
  // schedule

  const scheduleIsActive = select(".schedule-is-active");

  if (menuItem.classList.contains("sidebar-tennis-schedule")) {
    const scheduleContent = select(".schedule-content");

    if (scheduleContent.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    if (!scheduleIsActive) {
      CleareActiveSlider();
    }

    // ScheduleTennis();

    setTimeout(() => {
      scheduleContent.classList.add("active-slider");
      body.classList.add("schedule-is-active");
      menuItem.classList.add("active-slider-menu");
    }, 200);

    // tracking
    AnalyticsTracking("openSchedule", quickBarPartnerNameAnalitics);
  }

  // ------------------------------------------------
  // matchplay player search

  const matchplayPlayersIsActive = select(".matchplay-players-is-active");

  if (menuItem.classList.contains("sidebar-matchplay-players")) {
    const sidebarMatchplayPlayers = select(".matchplay-players-content");

    if (sidebarMatchplayPlayers.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    // acitve flight in information
    ActiveGroupIsideInformation(window["flights"]);

    // show matchplay-players
    if (objectLength(HQ.Properties.players) > 0) {
      MachplaySearch();
    }

    if (!matchplayPlayersIsActive) {
      CleareActiveSlider();
    }

    setTimeout(() => {
      sidebarMatchplayPlayers.classList.add("active-slider");
      body.classList.add("matchplay-players-is-active");
      menuItem.classList.add("active-slider-menu");
    }, 200);

    // tracking
    AnalyticsTracking("openMatchplayPlayers", quickBarPartnerNameAnalitics);
  }

  // ------------------------------------------------
  // matchplay player leaderboard

  const matchplayLeaderboardIsActive = select(
    ".matchplay-leaderboard-is-active"
  );

  if (menuItem.classList.contains("sidebar-matchplay-leaderboard")) {
    const sidebarMatchplayLeaderboard = select(
      ".matchplay-leaderboard-content"
    );

    if (sidebarMatchplayLeaderboard.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    // acitve flight in information
    ActiveGroupIsideInformation(window["flights"]);

    if (objectLength(HQ.Properties.matches) > 0) {
      MachplayLeaderBoard();
    }

    if (!matchplayLeaderboardIsActive) {
      CleareActiveSlider();
    }

    setTimeout(() => {
      sidebarMatchplayLeaderboard.classList.add("active-slider");
      body.classList.add("matchplay-leaderboard-is-active");
      menuItem.classList.add("active-slider-menu");
    }, 200);

    // tracking
    AnalyticsTracking("openMatchplayLeaderboard", quickBarPartnerNameAnalitics);
  }

  if (HQ.Properties.presentingPartnerLayout === "rydercup") {
    setTimeout(() => {
      // const rydercupTop = select(".rydercup-top");
      // if (!rydercupTop) return;
      // console.log(HQ.Properties.presentingPartnerLayout);
      RyderCupLogos("rydercup-quickbar");
    }, 300);
  }
});

// --------------------------------------------------
// close all slider

document.addEventListener("keydown", (e) => {
  const activeSlider = select(".active-slider");

  if (!activeSlider) return;

  if (e.key === "Escape") {
    CleareActiveSlider();
  }
});

// --------------------------------------------------
// search

document.addEventListener("click", (e) => {
  const target = e.target;
  // e.preventDefault();
  e.stopPropagation();

  const getAllDataLayerId = selectAll("[data-layer-id]");
  const getAllCheckboxes = selectAll(".switch > input");

  PopupDestroy();

  const iconStar = target.closest(".icon-star");

  if (!target.closest("#slider-wrapper") && !iconStar) {
    CleareActiveSlider();
  }

  // --------------------------------------------------
  // click on checkbox single row

  const openPlace = target.closest(".open-place");
  const switchInformation = target.closest(".switch-information");

  if (openPlace && !switchInformation) {
    const placeID = openPlace.getAttribute("data-place-id");

    ShowPlacePopup({ currentZoom: prevZoom, placeID, center: true });

    CleareActiveSlider();
  }

  // --------------------------------------------------
  // show children information

  const infoChildren = target.closest(".information-row");
  const switchContainer = target.closest(".switch-container");
  const informationParent = target.closest(".information-parent");
  if (
    infoChildren?.classList.contains("cursor-pointer") &&
    !switchContainer &&
    !informationParent
  ) {
    if (HQ.Properties.informationVersion != "v2") return;
    const config = {
      type: "create",
      id: +infoChildren.dataset.id,
      layer: infoChildren.dataset.layerId,
    };

    if (infoChildren.nextElementSibling?.classList.contains("info-level-2")) {
      infoChildren.nextElementSibling.remove();
      infoChildren.classList.remove("active-information-row");
      return;
    }

    ShowInformationAboutPlacesRow(window["resultsPlaces"], config);
    infoChildren.classList.toggle("active-information-row");
  }

  // swich on/off layer insite information slider

  const rowInformation = target.closest(".switch-information");

  if (rowInformation) {
    e.preventDefault();
    const layerID = rowInformation.parentNode.getAttribute("data-layer-id");

    let layer = window[layerID];

    const showAllLayer = layerID === "show-all-layers" ? true : false;

    const input =
      rowInformation.parentNode.lastElementChild.firstElementChild
        .firstElementChild;
    input.checked = !input?.checked;

    // update checkbox inside information div
    InformationSettingsUpdate(layerID, getAllDataLayerId);

    // --------------------------------------------------
    if (showAllLayer) {
      let firstCheckedIsChecked = getAllCheckboxes[0].checked;
      firstCheckedIsChecked = !firstCheckedIsChecked;

      if (firstCheckedIsChecked) {
        // console.log("a");
        getAllDataLayerId.forEach((element, index) => {
          if (index > 0) {
            map.removeLayer(window[element.dataset.layerId]);
          }
          getAllCheckboxes[index || 0].checked = false;
        });
      } else {
        // console.log("b");
        getAllDataLayerId.forEach((element, index) => {
          if (index > 0) {
            window[element.dataset.layerId].addTo(map);
          }
          getAllCheckboxes[index].checked = true;
        });
      }
    }

    if (layerID === "show-all-layers") return;

    // remove layer if exist
    if (map.hasLayer(layer)) {
      map.removeLayer(layer);
      return;
    }

    // console.log("layer", layer);
    layer.addTo(map);
  }

  // --------------------------------------------------
  // click on location
  const locatonButton = target.closest(".sidebar-location");

  if (locatonButton) {
    eraseCookie("HQLocationCookie");

    const locatonContent = select(".location-content");

    if (locatonContent.classList.contains("active-slider")) {
      CleareActiveSlider();

      return;
    }

    CleareActiveSlider();

    body.classList.add("location-is-active");

    // start location
    if (hasTouchScreen()) {
      window["lc"].start();
    }

    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 50}px`
    );
  }

  // --------------------------------------------------
  // click on icon star
  const clickOnIconStar = target.classList.contains("icon-star");
  const autoRow = target.closest("li");

  if (clickOnIconStar) {
    if (!autoRow) {
      if (objectLength(HQ.Properties.top5) > 0) {
        LeaderboardContentSlider.insertAdjacentElement(
          "beforeend",
          LeaderboardCreateRow("update")
        );

        LeaderBoardAddFavoritePlayer();
        if (HQ.Properties.showAds == "1") {
          CreateAdsSlotInLeaderBoard("leaderboard-update");
        }
      }
    } else {
      if (HQ.Properties.showAds == "1") {
        CreateAdsSlotInLeaderBoard("autocomplete-update");
      }
    }
  }

  // --------------------------------------------------
  // click on button X auto-clear reset data in "Player search"

  const autoClear = target.classList.contains("auto-clear");

  if (autoClear) {
    if (HQ.Properties.showAds == "1") {
      CreateAdsSlotInLeaderBoard("autocomplete-clear");
    }
  }

  // --------------------------------------------------
  // click on row in leaderboard

  const flightnumber = target.closest("[data-id-flightnumber]");

  if (flightnumber) {
    const flightNumber = flightnumber.getAttribute("data-id-flightnumber");
    const fullName = target.closest("[data-full-name]")?.dataset?.fullName;
    const dataMatchPlay = target.closest("[data-match-play]");

    if (!flightNumber || clickOnIconStar) return;

    const typeAction = dataMatchPlay
      ? "clickLeaderboardMatchPlay"
      : "clickLeaderboard";

    // TODO: same part in autocomplete #270 line
    map.eachLayer((layer) => {
      const checkFlights = layer?._icon?.className.includes(
        `flight-${flightNumber}`
      );

      if (layer instanceof L.Marker && checkFlights) {
        // close slider
        CleareActiveSlider();

        map.flyTo(layer.getLatLng(), map.getZoom());
        layer._icon.classList.add("animation");

        layer.openPopup();

        if (fullName) {
          // analutics only when click on row in leaderboard
          AnalyticsTracking(typeAction, { name: fullName });
        }
      }
    });
  }

  // --------------------------------------------------
  // click on row in player search matchPlay

  const dataFlightToPlace = target.closest("[data-flight-to-place]");

  if (dataFlightToPlace) {
    const playerID = dataFlightToPlace.getAttribute("data-id");
    const fullName = target.closest("[data-full-name]")?.dataset?.fullName;
    const goToArea = target.closest("[data-go-to-area]");
    const captain = target.closest("[data-captain]");
    const placeAreaID = target.closest("[data-place-area]")?.dataset.placeArea;

    if (!fullName) return;

    // #567 once again, click does not work when place invisible
    if (placeAreaID) {
      const iconDrivingRange = select(".information-row .icon-driving-range");
      if (iconDrivingRange) {
        const parentElement = iconDrivingRange.parentNode.dataset.layerId;
        const checkIfCheckboxCheck = select(
          `[data-layer-id=${parentElement}] input`
        );

        if (!checkIfCheckboxCheck.checked) {
          iconDrivingRange.parentNode.click();
        }
      }
    }

    let checkPlayer = false;

    const searchPlace = goToArea
      ? `icon-${goToArea.dataset.goToArea}`
      : captain
      ? `place-${playerID}`
      : `player-${playerID}`;

    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (
          layer.options.icon.options.className.search(searchPlace) !== -1 &&
          !checkPlayer
        ) {
          CleareActiveSlider();

          map.flyTo(layer.getLatLng(), map.getZoom());
          layer._icon.classList.add("animation");

          layer.openPopup();
          checkPlayer = true;

          if (fullName) {
            // analutics only when click on row in leaderboard
            AnalyticsTracking("clickPlayersearchMatchPlay", {
              name: fullName,
            });
          }
        }
      }
    });
  }

  // --------------------------------------------------
  // close slice click on button slider
  if (target.classList.contains("close-slider")) {
    CleareActiveSlider();
  }

  // --------------------------------------------------
  // click on highlights
  if (target.closest(".highlights-row")) {
    const { twits, showOnMap } = target.closest(".highlights-row").dataset;

    // if (showOnMap == "false") return;

    CleareActiveSlider();

    const config = {
      dataTwitsID: twits,
      clickType: "Highlight",
    };

    // ActiveInformation(config);
    showOnMap === "true" ? ActiveInformation(config) : AddTwitsToMap(config);
  }

  // --------------------------------------------------
  // click on program
  if (target.closest(".program-row") && !target.closest(".callendar-button")) {
    const { twits, showOnMap, programme, partner } =
      target.closest(".program-row").dataset;

    if (programme && showOnMap == "false") return;

    CleareActiveSlider();

    const config = {
      dataTwitsID: twits,
      clickType: "Program",
      partner,
    };

    showOnMap === "true" ? ActiveInformation(config) : AddTwitsToMap(config);
  }

  // --------------------------------------------------
  // click on calendar icon in programme slider
  if (target.closest(".callendar-button")) {
    const jsonCalendar = target.closest(".callendar-button").dataset.calendar;

    const {
      name,
      description,
      startDate,
      startTime,
      endDate,
      endTime,
      placeID,
    } = JSON.parse(jsonCalendar);

    const config = {
      iCalFileName: `${startDate}-${startTime}-${
        HQ.Properties.appType
      }-${HQ.Properties.i18n.slider.programme.toLowerCase()}`,
      name,
      description,
      startDate,
      startTime,
      endDate,
      endTime,
      options: [
        "Apple",
        "Google",
        "Outlook.com",
        "Microsoft365",
        "MicrosoftTeams",
        "iCal",
      ],
      language: HQ.Properties.lang,
      timeZone: HQ.Properties.timeZone,
      location: placeID ? `${document.location.origin}/place/${placeID}` : "",
    };

    atcb_action(config, target);
  }

  if (target.closest(".location-content")) {
    // --------------------------------------------------
    // Location accordions
    // how to turn GPS Locatoin on/off

    if (!target.classList.contains("accordion")) return;

    target.classList.toggle("is-open");

    let content = target.nextElementSibling;

    if (content.style.maxHeight) {
      //this is if the accordion is open
      content.style.maxHeight = null;
    } else {
      //if the accordion is currently closed
      content.style.maxHeight = content.scrollHeight + "px";
    }

    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 50}px`
    );
  }

  // --------------------------------------------------
  const iconHomeControl = target.classList.contains("icon-home");

  if (iconHomeControl) {
    window.open("https://www.eventhubhq.com/", "_blank");
  }

  const iconInformationControl = target.classList.contains("icon-infor");

  if (iconInformationControl) {
    const mediaQuery = "(max-width: 620px)";
    const mediaQueryList = window.matchMedia(mediaQuery);
    const sliderWrapper = select(".slider-wrapper");

    const informationContent = select(".icon-infor");
    const informationActive = select(".information-active");
    informationContent.classList.add("information-active");

    const leafletControlAttribution = select(".leaflet-control-attribution");
    leafletControlAttribution.classList.toggle("visible");
    if (informationActive) {
      informationContent.classList.remove("information-active");
      // sliderWrapper.classList.remove("d-none");
    } else {
      // if (mediaQueryList.matches) return;
      // sliderWrapper.classList.add("d-none");
    }
  }

  // --------------------------------------------------
  // click on share icon in popup

  const shareContent = target.closest(".share-content");
  if (shareContent) {
    const cardTitle = select(".card-title");
    const cardHole = select(".card-hole");
    cardTitle?.classList.toggle("text-white");
    cardHole?.classList.toggle("text-white");
    const socialLinksHighlights = select(".social-links-highlights");
    socialLinksHighlights.classList.toggle("d-none");
  }

  // --------------------------------------------------
  // copy link (url) when click on icon in popup

  const copyLink = target.closest(".copy-link");
  if (copyLink) {
    ClipboardWriteText(target.dataset.url);
  }

  // console.log("target", e);
  // --------------------------------------------------
  // add favorites to autocomplete

  // const doesNotPlay = target.closest(".does-not-play");

  // if (doesNotPlay) {
  //   const autoFooter = select(".auto-footer");
  //   autoFooter.classList.remove("d-none");
  //   setTimeout(() => {
  //     autoFooter.classList.add("d-none");
  //   }, 2000);
  // }

  const autoFav = target.closest(".auto-fav");

  if (autoFav) {
    e.preventDefault();

    const row = target.closest(".auto-row");
    const rowId = row.dataset.id;
    // const flightNumber = row.dataset.autoFlightnumber;
    const fullName = row.dataset.fullName;
    // console.log("dataAutoFlightnumber", dataAutoFlightnumber);

    const storageName = "HQfavoritePlayers";
    const getLocalStorage = getItemLocalStorage(storageName);

    const selectedPlayers = [];

    if (row.classList.contains("active-row")) {
      const getAllID = selectAll(`div[data-id="${rowId}"]`);
      getAllID.forEach((row) => {
        // if row is data-fav remove row
        if (row.hasAttribute("data-fav-autocomplete")) {
          row.remove();
        } else {
          row.classList.remove("active-row");
        }
      });
      // remove id from localStorage
      removeIdFromLocalStorage(rowId, storageName);

      markFavoritePlayers();

      window.autocomplete.rerender();
    } else {
      if (getLocalStorage) {
        // if local storage exist add id to array
        selectedPlayers.push(...new Set(getLocalStorage));
      }

      selectedPlayers.push(+rowId);
      row.classList.add("active-row");
      const newPlayers = [...new Set(selectedPlayers)];

      // create localStorage with new array
      setItemLocalStorage(storageName, newPlayers);

      // markFavoritePlayers
      markFavoritePlayers();

      AnalyticsTracking("setFavorite", { name: fullName });

      // console.log(getLocalStorage);

      window.autocomplete.rerender();
    }
  }

  // --------------------------------------------------

  const buttonLayersMultiCourse = target.closest(".button-layer");

  if (buttonLayersMultiCourse) {
    const { latlng, latlngMobile, zoom, zoomMobile } =
      buttonLayersMultiCourse.dataset;

    const layerLatlng = hasTouchScreen() ? latlngMobile : latlng;
    const layerZoom = hasTouchScreen() ? zoomMobile : zoom;

    const [lat, lng] = layerLatlng.split(",").map((x) => x.trim());

    map.flyTo([lat, lng], +layerZoom);
  }

  // ---------------------------------------------------

  const showMultiCourseLayersButton = target.closest(
    ".show-multi-course-layers"
  );
  if (showMultiCourseLayersButton) {
    const multiCourseWrapper = select(".multi-course-wrapper");
    multiCourseWrapper.classList.toggle("d-none");
  }

  // ---------------------------------------------------
  // switch tab in player seatch match

  const switchTeam = target.closest(".team");
  if (switchTeam) {
    SwitchTeams(target);
  }
});

// --------------------------------------------------
// set information checkbox

const getAllDataLayerId = selectAll("[data-layer-id]");
const getAllCheckboxes = selectAll(".switch > input");

InformationSettings(getAllDataLayerId, getAllCheckboxes);

// --------------------------------------------------
// set favorite players

markFavoritePlayers();

// --------------------------------------------------
// set programme information
ProgrammeCreateRow();

// --------------------------------------------------
// https://tomik23.github.io/leaflet-examples/#20.maxBound-map-restricts-the-view
// set maxBound map restricts the view

// https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/72

if (typeof HQ.Properties.maxBounds !== "undefined") {
  map.setMaxBounds(getBounds());
}

// --------------------------------------------------
// set fav player to leaderboard
LeaderBoardAddFavoritePlayer();

map.on("moveend", GetCenterOfMap);

// --------------------------------------------------
// tracking zoom

let prevZoom = map.getZoom();

map.on("zoomend", function (e) {
  let currZoom = map.getZoom();

  let diff = prevZoom - currZoom;
  if (diff > 0) {
    AnalyticsTracking("zoomOut", {
      zoom: prevZoom - 1,
    });
  } else if (diff < 0) {
    AnalyticsTracking("zoomIn", {
      zoom: prevZoom + 1,
    });
  }
  prevZoom = currZoom;

  // ------------------------------------------------
  // showing and hiding icons on the map
  // depending on the zoom level

  ShowHidePlacesOnMap(currZoom);
});

// --------------------------------------------------
// only one time show this information
// fist visit site ask for permission to use location

if (hasTouchScreen() && HQ.Properties.locatorActive == "1") {
  // add location button bottom +/-
  map.addControl(new LocationButton());

  window["lc"] = new LocateControl({
    position: "topright",
    drawCircle: true,
    showPopup: false,
    showCompass: true,
    cacheLocation: true,
    maxBounds:
      typeof HQ.Properties.maxBounds !== "undefined" ? getBounds() : "",
    keepCurrentZoomLevel: true,
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
     * I checked google maps mobile and desktop
     * On desktop:
     * maximumAge is set to 3E5 = 997 decimal
     * timeout 10 seconds and enagbleHighAccuracy is set to true
     *
     * On mobile:
     * timeout: 1E4 // 10000 - 10 seconds
     * maximumAge: 2E3 // 20000 - 20 seconds
     *
     * We need to reduce the maximumAge because we need the most accurate data.
     *
     */
    locateOptions: {
      enableHighAccuracy: true,
      timeout: 10 * 1000, // 10 seconds
      maximumAge: 3000,
    },
  }).addTo(map);

  const firstVisitCheck = getItemLocalStorage("HQfirstVisit");
  if (!firstVisitCheck) {
    setItemLocalStorage("HQfirstVisit", true);
    window["lc"].start();
  } else {
    window["lc"].start();
  }

  // --------------------------------------------------
  // focus
  // window.addEventListener("focus", () => {
  //   console.log("has focus");
  //   lc.start();
  //   console.log("lc 2");
  // });
}

// --------------------------------------------------
// center map click on button

map.addControl(new BackToHomeButton());

// --------------------------------------------------
// show first layer icon-flights

ShowFirstLayerFlights();

// --------------------------------------------------
// open popup when url have ?place=id id
// is a number from DB

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const placeID = urlParams.get("place") || urlParams.get("highlight");
if (placeID) {
  const paramsUrl = urlParams.has("place") ? "place" : "highlight";
  ShowPlacePopup({ currentZoom: prevZoom, placeID, paramsUrl });
}

// --------------------------------------------------
// Show player when /locator?player=21

ShowPlayerPopup(window["flights"]);

// --------------------------------------------------
// fix issue width height when image insite popup

// NOTE: disabling img fligth group-popup refresh
// updatePopupHeightCalculate();

// ------------------------------------------
// Multi-Course

ShowMultCourseLayers();

// ------------------------------------------
// match squad

ShowSquadPoints();

// --------------------------------------------------
// remove dataset from body about tweet number opened

map.on("popupclose", function (e) {
  document.body.dataset.tweetOpened = "";
});

map.on("popupopen", function (e) {
  const popup = e.popup;

  const getPopupElement = popup.getElement();

  const playerOnRange = getPopupElement.querySelector(".player-on-range");
  if (playerOnRange) {
    CreatePlayerOnRange(popup, getPopupElement, playerOnRange);
  }

  // ------------------------------------------------
  // twitter

  const twitterTweet = getPopupElement.querySelector(".twitter-tweet");
  if (twitterTweet) {
    // center popup
    let px = map.project(e.popup._latlng);
    px.y -= e.popup._container.clientHeight + 340 / 2;
    map.panTo(map.unproject(px));

    twitterTweet.parentNode.classList.add("twitter-wrapper");
    twitterTweet.parentNode.insertAdjacentHTML("afterbegin", timelineWrapper);
    twttr.widgets.load().then(function (el) {
      const timeline = select(".timeline-wrapper");
      timeline?.remove();
    });

    // remove background from close button
    // when device is touch screen
    const bloseButton = getPopupElement.querySelector(
      ".leaflet-popup-close-button"
    );
    bloseButton.style.backgroundColor = "transparent";
  }
});

// --------------------------------------------------
// bigscreen

if (HQ.Properties.mode === "bigscreen") {
  const timePerItem = urlParams.get("timePerItem");
  const items = urlParams.get("items");
  const centerZoom = urlParams.get("center_zoom");
  const centerLatlng = urlParams.get("center_latlng");

  root.style.setProperty("--hide-control", "none");
  // root.style.setProperty("--hide-quickbar", "none");

  ShowPlayerPopupBigscreen(timePerItem, items, centerZoom, centerLatlng);
}

// --------------------------------------------------
// show lat + lng in console log when
// click on map for testing purposes only

// map.on("click", function (e) {
//   console.log(e.latlng.lat + ", " + e.latlng.lng);
//   console.log(map.getZoom());
// });

// map.on("zoomend", function (e) {
//   const zoom = map.getZoom();
//   console.log(zoom);
// });
