import map from "@components/map";
import {
  centerPlaceInView,
  checkIfVarExist,
  select,
  selectAll,
} from "@utils/function";
import ShowHidePlacesOnMap from "./showHidePlacesOnMap";
import AddTwitsToMap from "./addTwitsToMap";
import AnalyticsTracking from "@helpers/analyticsTracking";
import CreatePlayerOnRange from "./matchPlay/createPlayerOnRange";

/**
 * Show player popup on id
 *
 * open popup on locator site
 * all places have place-id - id is a number from DB
 */
export default function ShowPlacePopup({
  placeID,
  currentZoom,
  clickType,
  partner,
  center,
  paramsUrl,
} = {}) {
  // check twits in Highlights
  //   console.log(paramsUrl);
  //   const dataTwits = select(`[data-twits="${placeID}"]`);
  if (paramsUrl == "highlight") {
    AddTwitsToMap({ dataTwitsID: placeID });
  }

  if (clickType) {
    clickType = clickType;
  } else {
    clickType = clickType ? "Highlight" : "Place";
  }

  if (placeID) {
    const zoom = Math.floor(currentZoom);
    // setView

    HQ.Properties.places.map(({ id, latitude, longitude, zoom_levels }) => {
      if (Number(id) === Number(placeID)) {
        const firstZoom = [...zoom_levels].shift();

        if (zoom < firstZoom && latitude) {
          // map.setZoom(firstZoom);
          ShowHidePlacesOnMap(firstZoom);

          if (center) return;
          map.setView([latitude, longitude], firstZoom, {
            animation: true,
          });
        }
      }
    });

    // click on specific checkbox in information slider
    const dataChildren = selectAll(".information-row");

    [...dataChildren].map((child) => {
      if (!checkIfVarExist(window[`marker-${placeID}`])) return;

      const childrenArray = child.dataset.children.split(",");
      const check = childrenArray.includes(placeID);

      if (check) {
        const checkPlaceOnID = map.hasLayer(window[`marker-${placeID}`]);
        const dataLayerId = child.dataset.layerId;

        if (!checkPlaceOnID) {
          const informationCheckbox = select(
            `[data-layer-id="${dataLayerId}"] > .switch-container`
          );
          informationCheckbox?.click();
        }
      }
    });

    // window[`marker-${placeID}`].addTo(map);

    const findPlaceByID = HQ.Properties.places
      .filter((c) => c.id == placeID)
      .map((el) => el)
      .pop();

    const { icon, name_tracking, zoom_levels } = findPlaceByID || {};
    const showPlaceParnter = partner ? partner : "";

    let checkPlayes = false;
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        const checkIfMarkerExistOnMap = L.DomUtil.hasClass(
          layer.options.icon.options,
          `place-${placeID}`
        );

        if (checkIfMarkerExistOnMap && !checkPlayes) {
          const partnerName = layer.options.icon.options.data;

          const partner = partnerName.hasOwnProperty("partner")
            ? partnerName.partner
            : showPlaceParnter
            ? showPlaceParnter
            : "";

          // https://stackoverflow.com/questions/22538473/leaflet-center-popup-and-marker-to-the-map
          // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/373
          // NOTE do we add this solution?
          // layer.setZIndexOffset(2000);

          // center marker in view

          //   layer.openPopup();

          layer.openPopup();
          if (center) {
            const getPopupHeight = layer._popup._container.clientHeight;
            const offsetHeight = getPopupHeight > 200 ? -getPopupHeight : 0;

            const checkIfPathExistOnMap = L.DomUtil.hasClass(
              layer.options.icon.options,
              "icon-hole"
            );

            let markerBounds = L.latLngBounds([layer.getLatLng()]);

            const zoomLevel = zoom_levels
              ? checkIfPathExistOnMap
                ? zoom_levels[3]
                : zoom_levels[zoom_levels.length - 1]
              : map.getZoom();

            // if (checkIfPathExistOnMap) {
            //   map.setView(layer.getLatLng(), zoomLevel);
            // } else {
            map.fitBounds(markerBounds, {
              maxZoom: zoomLevel,
              paddingBottomRight: [0, offsetHeight],
            });
            // }
          }

          updatePopupIfPlayerOnRange(layer._popup);

          setTimeout(() => {
            AnalyticsTracking(`click${clickType}`, {
              name: name_tracking,
              icon,
              icon_shortcode: null,
              // lat: Number(latitude),
              // lng: Number(longitude),
              partner,
            });
          }, 1000);
          checkPlayes = true;
        }
      }

      if (layer._path && icon != "icon-hole") {
        let checkPolygon = false;

        const checkIfPathExistOnMap = L.DomUtil.hasClass(
          layer._path,
          `place-${placeID}`
        );

        if (checkIfPathExistOnMap && !checkPolygon) {
          window[`area-${placeID}`].openPopup();

          map.panInsideBounds(layer.getLatLngs());

          checkPolygon = false;

          updatePopupIfPlayerOnRange(window[`area-${placeID}`]._popup);
        }
      }
    });
  }
}

function updatePopupIfPlayerOnRange(popup) {
  const getPopupElement = popup.getElement();
  const playerOnRange = getPopupElement?.querySelector(".player-on-range");

  if (!playerOnRange) return;
  CreatePlayerOnRange(popup, getPopupElement, playerOnRange);
}
