import map from "@components/map";
import {
  convertJSONToArray,
  select,
  svgIcon,
  getItemLocalStorage,
  GetColorFromCourse,
  CreateAdsSlotInLeaderBoard,
  ConvertToAscii,
} from "@utils/function";

import AnalyticsTracking from "@helpers/analyticsTracking";
import CleareActiveSlider from "./cleareActiveSlider";

const storageName = "HQfavoritePlayers";

const AutocompleteObj = {
  showAllValues: true,
  classPreventClosing: "prevent-close",

  onSearch: ({ currentValue }) => {
    let globalArray = [];

    window.data.map(
      ({
        lat,
        lng,
        status,
        startingtee,
        currenthole,
        holesplayed,
        course_id,
        teetime,
        players,
        matchnumber_str,
        matchnumber,
      }) => {
        let newArray = convertJSONToArray(players);

        const getLocalStorage = getItemLocalStorage(storageName);
        if (getLocalStorage) {
          newArray = addFavPlayerToPlayers(newArray, getLocalStorage);
        }

        newArray.forEach((player) => {
          globalArray.push({
            ...player,
            lat,
            lng,
            status,
            teetime,
            startingtee,
            currenthole,
            matchnumber_str,
            holesplayed,
            course_id,
            flightNumber: matchnumber,
          });
        });
      }
    );

    const flatArray = globalArray.flat();

    return flatArray
      .sort((a, b) => {
        if (a.clone && !b.clone) {
          return -1;
        } else if (!a.clone && b.clone) {
          return 1;
        } else if (a.clone && b.clone) {
          return 1;
        }
        // return a.name.localeCompare(b.name);
      })
      .filter((element) => {
        const elementNormalize = ConvertToAscii(element.fullname);
        const currentValueNormalize = ConvertToAscii(currentValue);

        return elementNormalize.match(new RegExp(currentValueNormalize, "i"));
      });
  },

  onRender: ({ results }) => {
    results.insertAdjacentHTML(
      "beforebegin",
      `<ul class="auto-head">
        <li class="auto-row row py-1">
          <div class="p-1 col-5 auto-player-name d-flex align-items-center">
            <span class="player-name-header">${
              HQ.Properties.i18n["autocomplete"]["player"]
            }</span>
            <span class="d-flex player-name-info">
              (<span class="player-icon-header">
                ${svgIcon({ color: "#fff", name: "icon-flights" })}
              </span> = playing)
            </span>
          </div>
          <div class="p-1 col-1 text-center auto-tee">${
            HQ.Properties.i18n["autocomplete"].tee
          }</div>
          <div class="p-1 col-2 text-center auto-thru">${
            HQ.Properties.i18n["autocomplete"].thru
          }</div>
          <div class="p-1 col-2 text-center text-truncate auto-group">${
            HQ.Properties.i18n["autocomplete"].group
          }</div>
          <div class="p-1 col-1 text-center text-truncate auto-country"></div>
          <div class="p-1 col-1 text-center text-truncate auto-fav">Fav</div>
        </li>
      </ul>`
    );
  },

  onResults: ({ currentValue, matches, template }) => {
    const regex = new RegExp(currentValue, "i");
    const autoHead = select(".auto-head");

    autoHead.classList[matches.length > 7 ? "add" : "remove"]("auto-pr");

    let show = true;

    // don't show autocomplete content
    if (HQ.Properties.isSocialMediaBot) return;

    return matches === 0
      ? template
      : matches
          .map(
            (
              {
                id,
                status,
                teetime,
                country,
                clone,
                fav,
                flightNumber,
                matchnumber_str,
                startingtee,
                proam,
                currenthole,
                holesplayed,
                course_id,
                totaltopar,
                fullname,
              },
              index
            ) => {
              totaltopar = !totaltopar ? "PAR" : totaltopar;

              if (proam) {
                proam = proam === "am" ? "(A)" : "";
              } else {
                proam = "";
              }
              let previusFlightNumber = matches[index - 1]?.flightNumber;
              show = previusFlightNumber === flightNumber ? false : true;

              const borderTop = show && !clone ? "border-top" : "";

              // TODO: create separate function, same logic in leaderboard
              let previusPositionFav = matches[index - 1]?.fav;
              const pos = fav
                ? flightNumber !== previusFlightNumber
                  ? flightNumber
                  : ""
                : flightNumber === previusFlightNumber && !previusPositionFav
                ? ""
                : flightNumber || "-";

              // show group position
              const showGroupPosition = pos ? flightNumber : pos;

              // show starting tee
              const showStartingtee = show
                ? `<small>${startingtee}</small>`
                : "";

              // -----------------------------------------------

              // show time
              let timeTemplate;
              let totaltoparTemplate;

              if (totaltopar < 0) {
                totaltoparTemplate = '<span class="text-danger">';
              } else if (totaltopar > 0) {
                totaltoparTemplate = '<span class="text-success">+';
              } else {
                totaltoparTemplate = "";
              }

              if (status == "PLAYING") {
                const typeStartingtee = startingtee !== 1 ? "*" : "";

                timeTemplate =
                  totaltoparTemplate +
                  totaltopar +
                  "</span> " +
                  holesplayed +
                  typeStartingtee;
              } else if (status == "FINISHED") {
                // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/380
                // const timeTee = compareTwoTimes(teetime.slice(0, 5))
                //   ? ""
                //   : `<small>${teetime.slice(0, 5)} F</small>`;
                // $timeTemplate = timeTee;
                // $timeTemplate = `<small>${teetime.slice(0, 5)} F</small>`;

                timeTemplate =
                  totaltoparTemplate + totaltopar + "</span> " + " F";
              } else {
                timeTemplate = `<small>${teetime.slice(0, 5)}</small>`;
              }

              const flightIcons =
                status === "TEE" || status === "PLAYING"
                  ? `<span class="player-icon">${svgIcon({
                      color: "#3e3e3e",
                      name: "icon-flights",
                    })}</span>`
                  : "";

              // does-not-play
              const preventClosingPlayerSearch =
                status !== "TEE" && status !== "PLAYING" ? "prevent-close" : "";

              const fullNameForDataAttribute = fullname
                ? `data-full-name="${fullname}"`
                : "";

              fullname = fullname.replace(
                regex,
                (str) => `<b style="color: #21325b;">${str}</b>`
              );

              const dataFav = clone ? 'data-fav-autocomplete="true"' : "";
              // const activeRow = fav ? "active-row" : "";

              const activeRow = addRowActive(id, fav);

              const flightnumber =
                flightNumber && currenthole
                  ? `data-auto-flightnumber="${matchnumber_str}"`
                  : "";

              // -------------------------------------
              const colorsMultiCourse = course_id
                ? `style="--course-color:${GetColorFromCourse(course_id)}"`
                : "";

              const rowWithAds =
                HQ.Properties.showAds == "1" && currentValue.length === 0
                  ? generateSlotForAdsInPlayerSearch(showGroupPosition, clone)
                  : "";

              return `
                ${rowWithAds}
                <li ${colorsMultiCourse} ${fullNameForDataAttribute} ${dataFav} data-id="${id}" class="row row-item ${borderTop} auto-row py-1 ${preventClosingPlayerSearch} ${activeRow}">
                  <div class="p-1 col-5 auto-player-name">
                  ${fullname}
                  ${proam}
                  ${flightIcons}
                  </div>
                  <div class="p-1 col-1 text-center auto-tee">
                    ${showStartingtee}
                  </div>
                  <div class="p-1 col-2 text-center auto-thru">
                    ${timeTemplate}
                  </div>
                  <div class="p-1 col-2 text-center auto-group">
                    ${showGroupPosition}
                  </div>
                  <div class="p-1 col-1 text-center auto-country">
                    <span title="${country}" class="flag-icon flag-icon-${country.toLowerCase()}"></span>
                  </div>
                  <div class="p-1 col-1 text-center auto-fav prevent-close">
                    <div class="icon-star">
                      <svg><use href="#icon-star"></use></svg>
                    </div>
                  </div>
                </li>`;
            }
          )
          .join("");
  },

  onSubmit: ({ object }) => {
    // console.log(object);
    // TODO: same part in leaderboard, move logic to one function

    if (!object?.currenthole) return;

    const playerID = object.matchnumber_str;

    // let checkPlayer = false;
    map.eachLayer((layer) => {
      const checkFlights = layer?._icon?.className.includes(
        `flight-${playerID}`
      );

      if (layer instanceof L.Marker && checkFlights) {
        // close slider
        CleareActiveSlider();

        map.flyTo(layer.getLatLng(), map.getZoom());
        layer._icon.classList.add("animation");

        layer.openPopup();

        // tracking event
        if (object.fullname) {
          AnalyticsTracking("clickPlayersearch", { name: object.fullname });
        }
      }
    });
  },

  onClose: () => {
    const searchPlayers = document.querySelector("#search-players");
    searchPlayers?.click();
  },

  onOpened: ({ element }) => {
    // remove (A) from input
    element.value = element.value.replace("(A)", "").trim();
  },

  onReset: () => {
    if (HQ.Properties.showAds == "1") {
      CreateAdsSlotInLeaderBoard("open player search");
    }
  },

  noResults: ({ element, template }) => {
    template(
      `<li class="row row-item py-1 auto-row">
        <div class="p-2 col-12 auto-not-found">No player found: "${element.value}"</div>
      </li>`
    );
  },
};

/**
 * Create new array with cloned object (favorites player)
 *
 * @param {Array} newArray
 * @param {Array} getLocalStorage
 * @returns Array
 */
function addFavPlayerToPlayers(newArray, getLocalStorage) {
  const findObjectInLocalstorage = newArray
    .filter(({ id }) => getLocalStorage.includes(id))
    .map((x) => (x = { ...x, clone: true, fav: true }));

  const findObject =
    findObjectInLocalstorage.length > 0
      ? [...newArray, ...findObjectInLocalstorage]
      : "";

  return findObject ? findObject : newArray;
}

/**
 * Add active-row class, depending on whether the id is
 * in localstorage and whether it is in fav === true
 *
 * @param {Number} id
 * @param {String} fav
 * @returns String
 */
function addRowActive(id, fav) {
  const getLocalStorage = getItemLocalStorage(storageName);
  if (!getLocalStorage) return;

  return getLocalStorage.includes(id) || fav ? "active-row" : "";
}

/**
 * Cenerate row "slot" for ads
 *
 * @param {Number} indexGroup
 */
function generateSlotForAdsInPlayerSearch(indexGroup, clone) {
  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/350
  // 2,5,10,25,40

  const group = [3, 6, 11, 26, 41];

  //   const midcontentRows = [
  //     { md: "midcontent" },
  //     { md: "midcontent2" },
  //     { md: "midcontent3" },
  //     { md: "midcontent4" },
  //     { md: "midcontent5" },
  //   ];

  if (group.includes(indexGroup) && !clone) {
    let idx = group.indexOf(indexGroup);

    let index = indexGroup > 3 ? `-index-${idx}` : "";

    // const md = midcontentRows[idx].md;
    //  data-md="${md}"
    return `
      <aside class="advertisement">
        <div class="advertisement__container">
          <div

            data-base-slot="1"
            id="pb-slot-leader-1${index}"
            class="ads-row text-center"
          ></div>
        </div>
      </aside>
    `;
  } else {
    return "";
  }
}

export default AutocompleteObj;
