import { select, body } from "@utils/function";
import { selectAll } from "@utils/function";

const sliders = ["players-content", "leaderboard-content"];

/**
 * Clear active slider and remove active class
 */
export default function CleareActiveSlider() {
  const activeSliderClassName = "active-slider";
  const activeSliderMenuClassName = "active-slider-menu";

  const activeSlider = select(`.${activeSliderClassName}`);
  activeSlider?.classList.remove(activeSliderClassName);

  const activeSliderMenu = select(`.${activeSliderMenuClassName}`);
  activeSliderMenu?.classList.remove(activeSliderMenuClassName);

  // window.tude.destroyAds();

  removeContentFromQuickbarkSlider(activeSlider);
  //   removeAllActiveRowAndSubmenu();

  const sliders = [
    "information-is-active",
    "players-is-active",
    "leaderboard-is-active",
    "location-is-active-error",
    "location-is-active",
    "highlights-is-active",
    "programme-is-active",
    "schedule-is-active",
    "results-is-active",
    "matchplay-players-is-active",
    "matchplay-leaderboard-is-active",
  ];

  sliders.forEach((slider) => {
    body.classList.remove(slider);
  });
}

function removeContentFromQuickbarkSlider(activeSlider) {
  sliders.forEach((slider) => {
    const sliderElement = activeSlider?.classList.contains(slider);
    if (sliderElement) {
      const sliderContent = select(`.${slider} > .slider-content`);
      sliderContent.textContent = "";
    }
  });
}

function removeAllActiveRowAndSubmenu() {
  const activeRow = selectAll(".active-information-row");
  activeRow.forEach((row) => {
    row.nextElementSibling.remove();
    row.classList.remove("active-information-row");
  });
}
