// --------------------------------------------------
// active Group checkbox iside Information
// when click on Player Search
// and show gorup (flights) on map

import { isObjectEmpty, select } from "@utils/function";

export default function ActiveGroupIsideInformation(flightsLayer) {
  const groupExistOnMap = map.hasLayer(window["flights"]);

  if (!isObjectEmpty(flightsLayer._layers) && !groupExistOnMap) {
    const informationCheckbox = select(
      `[data-layer-id="flights"] > .switch-container`
    );
    informationCheckbox?.click();

    // click on specific checkbox in information slider
    const drivingRange = select(
      `[data-layer-id^="driving-range"] > .switch-container`
    );
    drivingRange?.click();
  }
}
