import { iconsType } from "./iconsType";
import { defaults } from "@helpers/defaults";

import DataMarkers from "./dataMarkers";

export default function CustomIcon({
  id,
  icon,
  content = "",
  iconConfig = defaults.iconSize,
  matchnumber_str,
  color,
  color_front,
  color_outline,
  players,
  partner,
}) {
  const { w, h } = iconConfig;

  // console.log(players);

  const playersClass = players?.map((player) => `player-${player} `);

  const playerWidthId = playersClass ? playersClass : "";

  // this icon use in auto complete when click on player
  const flighIcon =
    icon === "icon-flights"
      ? ` flight-${matchnumber_str} ${playerWidthId.join("")}`
      : "";

  const idPlace = id ? ` place-${id}` : "";
  let data = partner ? { partner: partner.name } : "";

  const markerWithDataAttribute = new DataMarkers({
    html: iconsType({
      icon,
      content,
      color,
      color_front,
      color_outline,
      players,
    }),
    data,
    className: `icon ${icon}${flighIcon}${idPlace}`,
    iconSize: [w, h],
    iconAnchor: [icon === "icon-hole" ? 17 : 22, 20],
    popupAnchor: [-4, -20],
  });

  return markerWithDataAttribute;
}
